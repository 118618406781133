@tailwind base;
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) var(--primary);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 2px;
}

*::-webkit-scrollbar-track {
  /* background: var(--primary); */
  border-radius: 2px;
}

*::-webkit-scrollbar-thumb {
  /* background-color: var(--secondary); */
  border-radius: 2px;
  /* border: 3px solid var(--primary); */
}

@tailwind components;
@tailwind utilities;